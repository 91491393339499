<template>
  <div class="inteview-index">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'ScheduleInterview',
  data: () => {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.inteview-index {
  width: 100%;
  height: 100vh;
  background: #ffffff;
}
</style>
